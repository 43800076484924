import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

/*
 * Includes and Imports
 */

import '@/components/_globals'
import '@/plugins/fullpage.dragAndMove.min.js'
import VueFullPage from 'vue-fullpage.js'
import VueTippy, { TippyComponent } from "vue-tippy";

Vue.use(VueFullPage);
Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

/*
 * Settings
 */

// Force SSL
if(window.location.protocol != "https:" && window.location.hostname.indexOf('localhost')===-1) {
  location.replace(`https:${location.href.substring(location.protocol.length)}`)  
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
